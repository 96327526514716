<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { useRouter } from './packs/utils'
import LayoutBlank from './packs/layouts/Blank.vue'
import LayoutContent from './packs/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  computed: {
    resolveLayout: () => {
      const { route } = useRouter()
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    }
  },
}
</script>
