import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

async function authUser(next, to = null) {
  // Load JWT from local storage on page refresh
  const localAuthToken = localStorage.getItem("authToken")
  let tokenExists = localAuthToken !== 'undefined' && localAuthToken !== null
  if (tokenExists) {
    const authToken = localStorage.getItem("authToken")
    if (authToken !== 'undefined' && authToken !== null) {
      await store.dispatch("LOG_IN_WITH_TOKEN", { authToken })
    }
  }
  if (store.getters.isLoggedIn) {
    next()
  } else {
    next("/login")
  }
}

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    beforeEnter(to, from, next) { authUser(next, to) }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard/Dashboard.vue'),
    beforeEnter(to, from, next) { authUser(next, to) }
  },
  {
    path: '/prefill-urls',
    name: 'prefill_urls',
    component: () => import('../views/pages/prefill-urls/PrefillUrlsWizard.vue'),
    beforeEnter(to, from, next) { authUser(next, to) }
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/pages/history/History.vue'),
    beforeEnter(to, from, next) { authUser(next, to) }
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('../views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('../views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('../views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('../views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('../views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('../views/pages/account-settings/AccountSettings.vue'),
    beforeEnter(to, from, next) { authUser(next, to) }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    }
  },
  // {
  //   path: '/signup',
  //   name: 'register',
  //   component: () => import('../views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/pages/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('../views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
