import Vue from 'vue'
import Vuex from 'vuex'
import userManager from "./modules/userManager"
import organization from "./modules/organization"
import upload from "./modules/upload"
import admin from "./modules/admin"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    userManager,
    organization,
    upload,
    admin
  },
})
