import Api from "../../utils/api";

const state = {
    organization: {
        info: {
            id: null,
            name: null,
            address: null,
            website: null,
            state: null,
            email: null,
            phone: null,
        },
        org_providers: null
    }
}

const getters = {
    getOrganization(state) { return state.organization }
}

const actions = {
    CREATE_ORGANIZATION({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post(`/organizations`, payload)
                .then(res => {
                    resolve({
                        status: res.status,
                        data: res.data
                    })
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    GET_ALL_ORGANIZATIONS({ commit }) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    DEACTIVATE_OGANIZATION({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.id}/deactivate`)
                .then(res => {
                    resolve({
                        status: res.status,
                        id: res.data.id
                    })
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    DEACTIVATE_USER({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.id}/user/${payload.userId}/deactivate`)
                .then(res => {
                    resolve({
                        status: res.status,
                        org: res.data
                    })
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    // We may want to change this to "Update org"
    UPDATE_INFO({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().put(`/organizations/${payload.id}`, payload.body)
                .then(res => {
                    commit('UPDATE_ORG_INFO', res)
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    UPDATE_PROVIDERS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post(`/organizations/${payload.id}/update-providers`, payload.body)
                .then(res => {
                    commit('UPDATE_ORG_PROVIDERS', res)
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    GET_AVAILABLE_FORMS({ commit }, orgId) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${orgId}/get-available-forms/`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    GET_FORM_PREFILL_QUESTIONS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.orgId}/get-form-questions/${payload.formId}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    },
    GET_CONTACT_FIELDS_IN_CRM({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.orgId}/get-crm-contact-fields`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    },
    GET_LISTS_IN_CRM({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.orgId}/get-lists`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    },
    GET_TAGS_IN_CRM({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.orgId}/get-tags`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    },
    GET_ACTIVE_AUTOMATIONS({ commit }, orgId) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${orgId}/get-active-automations`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    },
    GET_TRACKED_AUTOMATIONS_IN_CRM({ commit }, orgId) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${orgId}/get-tracked-automations`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    },
    GET_CAMPAIGNS_BY_AUTOMATION_ID_IN_CRM({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/organizations/${payload.orgId}/automations/${payload.automationId}/campaigns`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })    
        })
    }
}

const mutations = {
    SET_CURRENT_ORG(state, payload) {
        state.organization = Object.assign({}, payload.data.organization)
    },
    CLEAR_CURRENT_ORG(state) {
        state.organization = {
            info: {
                id: null,
                name: null,
                address: null,
                website: null,
                state: null,
                email: null,
                phone: null
            },
            org_providers: null
        }
    },
    UPDATE_ORG_INFO(state, payload) {
        state.organization.info = payload.data
    },
    UPDATE_ORG_PROVIDERS(state, payload) {
        state.organization.org_providers = payload.data.org_providers
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}