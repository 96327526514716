// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('dotenv').config()

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import App from '../app.vue'
import vuetify from '../styles/plugins/vuetify'
import router from './router'
import store from './store'
import VueCompositionAPI from '@vue/composition-api'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Vuelidate from "vuelidate"
import Notifications from 'vue-notification'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(TurbolinksAdapter)
Vue.use(VueCompositionAPI)
Vue.use(Vuetify)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.component('app', App)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '[data-behavior="vue"]',
    router,
    store,
    vuetify,
  })
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
