import Api from "../../utils/api";

const actions = {
    ADMIN_UPDATE_USER({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().put(`admin/update_user`, payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 401 || err.response.status === 422) {
                        resolve({ errors: err })
                    }
                    reject(err)
                })
        })
    },
}

export default {
    actions
}
