import axios from 'axios'
import store from '../store'

export default () => {
	return axios.create({
		baseURL: process.env.API_HOST_URL,
		withCredentials: false,
		timeout: 900000,
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json",
			"Authorization": store.state.userManager.authToken
		}
	})
}