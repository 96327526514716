import Api from "../../utils/api";

const actions = {
    GET_PRESIGNED_URL({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post(`/prefilled_urls/get-presigned-url`, payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    },
    UPLOAD_TO_S3({ commit }, payload) {
        return fetch(
            payload.url, 
            {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': payload.file.type }),
                body: payload.file
            }
        )
    },
    GENERATE_PREFILL_URLS_IN_LAMBDA({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post('/prefilled_urls/generate-prefill-urls', payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    resolve({
                        url: payload.s3Filepath.split('?')[0].replace('csv-files', 'prefill-results')
                    })
                    // reject(err)
                })
        })
    },
    GET_LAST_10_PREFILL_RUNS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().get(`/prefill_runs/${payload.userId}/latest_ten`, payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    if (err.response.status === 400) {
                        resolve({ errors:  err })
                    }
                    reject(err)
                })
        })
    }
}

export default {
    actions
}