import Api from "../../utils/api";
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';

const state = {
    authToken: null,
    user: {
        id: null,
        email: null
    },
    providers: {
        crm: null,
        form: null
    }
}

const getters = {
    getAuthToken(state) { return state.authToken },
    getUserEmail(state) { return state.user?.email },
    getUserId(state) { return state.user?.id },
    isLoggedIn(state) {
        const loggedOut = state.authToken == null || state.authToken == JSON.stringify(null)
        return !loggedOut
    },
    isAdmin(state) { return state.user != null && state.user.role === 'admin' },
    getAvatar(state) {
        let img = createAvatar(initials, {
            seed: state.user.name || state.user.email,
            backgroundColor: ['660033'],
        })
        return img.toDataUriSync()
    }
}

const actions = {
    REGISTER({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post("signup", payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    LOG_IN({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post("login", payload)
                .then(res => {
                    commit('SET_CURRENT_USER', res)
                    commit('SET_CURRENT_ORG', res, { root: true })
                    resolve(res.data)
                })
            .catch(err => {
                if (err.response.status === 401) {
                    resolve({ errors:  err })
                }
                reject(err)
            })
        })
    },
    LOG_IN_WITH_TOKEN({ commit }, payload) {
        const config = {
            headers: { Authorization: payload.authToken }
        }
        return new Promise((resolve, reject) => {
            Api().get("get-user-with-token", config)
                .then(res => {
                    commit('SET_CURRENT_USER_FROM_TOKEN', res)
                    commit('SET_CURRENT_ORG', res, { root: true })
                    resolve(res.data)
                })
            .catch(err => {
                if (err.response.status === 401) {
                    resolve({ errors:  err })
                }
                reject(err)
            })
        })
    },
    LOG_OUT({ commit }) {
        const config = {
            headers: { authorization: state.authToken }
        }
        return new Promise((resolve, reject) => {
            Api().delete("logout", config)
                .then(res => {
                    commit('CLEAR_CURRENT_USER', res)
                    commit('CLEAR_CURRENT_ORG', res, { root: true })
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    RESEND_INVITE({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post("resend-invite", payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    FORGOT_PASSWORD(creds) {
        return new Promise((resolve, reject) => {
            Api().post("forgot_password", creds)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    UPDATE_PASSWORD({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().post("passwords/update", payload)
                .then(res => {
                    resolve(res.data)
                })
        })
    },
    UPDATE_CURRENT_USER({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Api().put(`users/${payload.id}`, payload.body)
                .then(res => {
                    commit('SET_CURRENT_USER_FROM_UPDATE', res)
                    resolve(res.data)
                })
            .catch(err => {
                if (err.response.status === 422) {
                    resolve({ errors:  err })
                }
                reject(err)
            })
        })
    }
}

const mutations = {
    SET_CURRENT_USER(state, payload) {
        state.user = payload.data.user
        state.providers = payload.data.providers
        state.authToken = payload.headers.authorization
        localStorage.setItem("authToken", payload.headers.authorization)
        localStorage.setItem("currentUser", JSON.stringify(payload.data))
    },
    SET_CURRENT_USER_FROM_TOKEN(state, payload) {
        state.user = payload.data.user
        state.providers = payload.data.providers
        state.authToken = localStorage.getItem("authToken")
    },
    SET_CURRENT_USER_FROM_UPDATE(state, payload) {
        state.user = payload.data
        localStorage.setItem("currentUser", JSON.stringify(payload.data))
    },
    CLEAR_CURRENT_USER(state) {
        state.user = {
            id: null,
            email: null
        }
        state.providers = {
            crm: null,
            form: null
        }
        state.authToken = null
        window.localStorage.removeItem("authToken")
        window.localStorage.removeItem("currentUser")
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}